import { defineStore } from 'pinia';
import { auth } from '@/main'
import { 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged, 
  createUserWithEmailAndPassword, 
  signInWithPopup, 
  GoogleAuthProvider 
} from 'firebase/auth';
import router from '@/router'; 

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    loading: false,
    error: null,
  }),
  actions: {
    async register(email, password) {
      this.loading = true;
      this.error = null;
      try {
        await createUserWithEmailAndPassword(auth, email, password);
        console.log("User registered successfully!");
        router.push('/home');
      } catch (error) {
        console.error(error.code);
        alert(error.message);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async googleLogin() {
      this.loading = true;
      this.error = null;
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        console.log("User logged in successfully with Google!");
        console.log(result.user);
        router.push('/home');
      } catch (error) {
        console.error(error.code);
        alert(error.message);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    async login(email, password) {
      this.loading = true;
      this.error = null;
      try {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("User logged in successfully!");
        router.push('/home');
      } catch (error) {
        console.error(error.code);
        alert(error.message);
        this.error = "Invalid email or password.";
      } finally {
        this.loading = false;
      }
    },
    async logout() {
      this.loading = true;
      this.error = null;
      try {
        await signOut(auth);
        this.user = null;
        console.log("User logged out successfully!");
        router.push('/main');
      } catch (error) {
        console.error(error.code);
        alert(error.message);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    init() {
      onAuthStateChanged(auth, (user) => {
        this.user = user;
      });
    }
  }
});
