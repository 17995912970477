<template>
    <div class="subscribe-button">
        <ButtonTemplate :button-text="'Iscriviti!'" @click="showPopup"/>
    </div>
    <div v-if="showFreeTrialForm" class="popup">
      <FreeTrialForm @closePopup="closePopup" />
    </div>

</template>

<script>
import ButtonTemplate from './button.vue';
import FreeTrialForm from '../screens/FreeTrial.vue';

export default {
    name: 'SubscribeButton',
    components: {
        ButtonTemplate,
        FreeTrialForm
    },
    data() {
        return {
        showFreeTrialForm: false
        };
    },
    methods: {
    showPopup() {
      this.showFreeTrialForm = true;
    },
    closePopup() {
      this.showFreeTrialForm = false;
    }
  }
  }
</script>

<style lang="scss" scoped>
.subscribe-button{
    --button-border-color: #04d21f;
    --button-text-color: #ffffff;
    --button-background-color: #00e91f;
    --button-background-hover-color: #0bc023;
  }
</style>