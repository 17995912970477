<template>
  <div class="pricing-page">
    <div class="title">
      <h2>Prezzi</h2>
    </div>
    <div class="user-container">
      <div class="basic">
        <BasicUser />
      </div>
      <div class="premium">
        <BasicUser />
      </div>
      <div class="elite">
        <BasicUser />
      </div>
    </div>
  </div>
</template>

<script>
import BasicUser from '../screens/BasicUser.vue';

export default {
  name: 'PricingPage',
  components: {
    BasicUser
  }
};
</script>

<style scoped>


.title {
  margin-bottom: 20px;
  margin-top: 30px;
  color: #9e3e15;
  text-align: center;
}

.user-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.basic{
  flex:1;
  margin-left: 40px;
}
.premium,
.elite {
  flex: 1;
}
</style>
