<template>
    <div v-if="!ShowExperiencePage" class="search-results">
      <div class="title-section">
        <h2>Storytelling Metrics</h2>
      </div>
      <div class="score-section">
        <div class="score-info">
          <p>Abbiamo calcolato il tuo score</p>
          <div class="score-bar">
            <div class="score-fill" :style="{ width: sessionstore.storytellingScore * 10 + '%' }"></div>
          </div>
          <div class="score-value">{{ sessionstore.storytellingScore }}</div>
        </div>
      </div>
      <div class="score-feedback">
        <div v-if="sessionstore.storytellingScore >= 0 && sessionstore.storytellingScore <= 5" class="feedback-text">{{ TextPlaceholders[0] }}</div>
        <div v-else-if="sessionstore.storytellingScore >= 6 && sessionstore.storytellingScore <= 8" class="feedback-text">{{ TextPlaceholders[1] }}</div>
        <div v-else class="feedback-text">{{ TextPlaceholders[2] }}</div>
      </div>  
    </div>
  </template>
  
  <script>
  import { useSessionStore } from '@/stores/sessionStore';

  export default {
    setup() {
      const sessionstore = useSessionStore()

      return {
        sessionstore
      };
    },
    name: "StoryTellingResults",
    data() {
    return {
      ShowExperiencePage: false,
      TextPlaceholders: [
        "L'azienda sembra non avere una strategia di storytelling definita. La comunicazione sui social media appare disorganizzata e poco coinvolgente. Manca una narrazione chiara e coesa attorno al brand, risultando in un'esperienza del cliente poco memorabile. Il livello di coinvolgimento e l'efficacia della comunicazione sono bassi, indicando la necessità di un miglioramento significativo nella gestione della narrativa aziendale",
        "L'azienda dimostra una discreta capacità di storytelling sui social media. La comunicazione risulta essere più organizzata rispetto al voto precedente, con tentativi di coinvolgere il cliente attraverso una narrazione coerente. Tuttavia, ci sono ancora margini di miglioramento evidenti. La narrativa potrebbe essere resa più avvincente e il coinvolgimento del cliente potrebbe essere incrementato attraverso strategie più innovative e originali",
        "L'azienda eccelle nel suo approccio al storytelling sui social media. La comunicazione è estremamente efficace e coinvolgente, con una narrazione ben definita che trasmette chiaramente il messaggio del brand. La capacità di coinvolgere il cliente è notevole, e la presenza online complessiva riflette un'attenta cura nella costruzione della narrativa aziendale. Complimenti all'azienda per un'eccellente esecuzione del storytelling",
      ]
    };
  },
  methods: {
    showPage() {
      this.ShowExperiencePage = true;
    },
  }
  };
  </script>
  
  <style scoped>
.search-results {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 90%;
  min-height: 100vh;
  top: 50px;
  margin-top: 50px;
}

.title-section {
  position: relative;
  left: 0px;
  margin-top: 20px;
  font-size: large;
}

.score-section {
  text-align: center;
  margin-top: 50px;
  top: 50px;
}

.score-info {
  display: flex;
  flex-direction: column;
}

.score-bar {
  width: 500px;
  height: 25px;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 10px auto;
  overflow: hidden;
}

.score-fill {
  height: 100%;
  background-color: #29d;
}

.score-value {
  font-size: 50px;
  font-weight: bold;
}

.metrics-section {
  margin-top: 20px;
}

.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  margin-top: 30px;
}

.result {
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
}

.title {
  padding: 10px;
  font-size: 13px;
  font-weight: lighter;
  text-align: left;
}

.content {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.metric {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.additional-info {
  margin-top: 20px;
}

.score-feedback {
  max-width: 70%;
  padding: 10px;
  border-radius: 15px;
  background-color: #29e176;
  text-align: center;
  margin: 0 auto; 
  display: flex; 
  justify-content: center; 
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.feedback-text {
  word-wrap: break-word;
}
</style>
