<template>
<div class="class app-wrapper">
  <div class="class app">
    <Navigation/>
    <router-view />
    <FooterPage class="footer"/>
  </div>
</div>
</template>

<script>
import Navigation from './components/Navigation.vue';
import FooterPage from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Navigation,
    FooterPage,
  },
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
}

.footer{
  margin-top: auto;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.link {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  color: black;
}

.link-light {
  color: #fff;
}
</style>
