<template>
    <div class="body">
        <div class="elements">
            <div class="top">
                <div class="price">
                    0€
                </div>
                <div class="label">
                    utente/mese | addebitato mensilmente
                </div>
            </div>
            <div class="mid">
                <div class="name">
                    Utente Basic
                </div>
                <div class="subscribe">
                    <SubscribeButton/>
                </div>
            </div>
            <div class="features">
                <div class="data-item">
                    <p><strong>10 Industrie Disponibili</strong></p>
                </div>
                <div class="data-item">
                    <p><strong>10 Industrie Disponibili</strong></p>            
                </div>
                <div class="data-item">
                    <p><strong>10 Industrie Disponibili</strong></p>            
                </div>
                <div class="data-item">
                    <p><strong>10 Industrie Disponibili</strong></p>
                </div>
            </div>
        </div>    
    </div>
</template>

<script>
import SubscribeButton from '../widgets/SubscribeButton.vue';
export default {
   name : 'BasicUser',
   components: {
    SubscribeButton
   }
};

</script>

<style lang="scss" scoped>
.body {
   border: 5px solid #ccc; /* Border style */
   padding: 20px; /* Padding inside the box */
   margin: 20px 20px 20px 20px;  /* Margin outside the box */
   box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 4px 6px -1px rgba(0, 0, 0, 0.06);
   border-radius: 10px;
   width: 400px;
   height: 1000px
}
.elements{
    display: flex;
    flex-direction: column;
}
.price{
    font-weight: bold;
    font-size: 40px;
    color: rgb(0, 166, 255);
}
.label{
    font-size: 15px;
    font-weight: light;
}
.name{
    font-weight: bold;
    font-size: 30px;
    color: rgb(109, 199, 248);
    margin-bottom: 10px;
}
.mid{
    margin-top: 20px;
    margin-bottom: 20px;
}
.data-item {
 margin-bottom: 10px;
 white-space: nowrap; 
}
.data-item:last-child {
   margin-bottom: 50px;
}

.subscribe{
    text-align: center;
}

</style>